import { useState } from "react";

import EditOrganization from "./EditOrganization";
import EditLocation from "./EditLocation";
import EditCourse from "./EditCourse";

export default function OrganizationCreate() {
	const [state, setState] = useState({
		step: 1
	});
	
	// seem to need to keep this, if we just return from OrganizationCreate
	// react complains about hooks not being called.
	const Render = (props) => {
		console.log('render', props.state.step);
		switch (props.state.step) {
			case 1:
				return EditOrganization(props.state, setState);
			case 2:
				return EditLocation(props.state, setState);
			case 3:
				return EditCourse(props.state, setState);
			default: 
				console.log('render unhandled step');
				break;
		}
	};
	
	return <Render state={state} />;
}