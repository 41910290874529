import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// no session exists, generate a session id and redirect to it 
// (so eg. page refreshes dont end up resetting progress)
export const ScorecardGenerateSession = () => {
	const navigate = useNavigate();
	
	// make up a session id
	const makeid = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	// validate/load course
	useEffect(() => {		
		let sessionId = makeid(20);
		return navigate(`${sessionId}/`);
	}, [navigate]);
};