import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import { ServerLogout } from 'API';
import { UserContext } from 'UserContext';
import { LogOut } from 'User';

export default function Logout() {
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	
	useEffect(() => {
		if (!user) {
			navigate('/');
			return;
		}
		ServerLogout()
		.then(function(response) {
			// handle case where response is a bad token error - log out anyway.
			if (response?.data?.success || (false === response?.data?.success && 'bad_token' === response?.data?.error)) {
				navigate('/');
			}
		})
		.finally(function() {
			// do this after serverlogout call so logout api call gets authenticated with token
			LogOut(setUser);
		});
	}, [navigate, user, setUser]);
}