import React, { useRef, useState, useEffect } from 'react';

import { renderToString } from 'react-dom/server'

import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { Google, Facebook, Globe } from 'react-bootstrap-icons';

import { ReactComponent as TripAdvisor } from 'tripadvisor.svg';

// single infoWindow so closing works.
var infoWindow = null;

export default function DirectoryMap({ apiKey, entries }) {
	const render = (status) => {
		if (status === Status.LOADING) return <h5>Loading map ..</h5>;
		if (status === Status.FAILURE) return <h3>{status} ...</h3>;
		return null;
	};
	
	const Marker = (options) => {
		const [marker, setMarker] = useState();

		useEffect(() => {
		      if (!marker) {
			      var m = new window.google.maps.Marker();
			      m.addListener('click', () => {
				      // create on first click
				      if (!infoWindow) { 
					      infoWindow = new window.google.maps.InfoWindow();
				      }
				      infoWindow.setContent(renderToString(options.content));
				      infoWindow.open(options.map, m);
			      });
			      setMarker(m);
		      }
		      // remove marker from map on unmount
		      return () => {
			      if (marker) {
				      marker.setMap(null);
			      }
		      };
		}, [marker, options.content, options.map]);
		
		useEffect(() => {
			if (marker) {
				marker.setOptions(options);
			}
		}, [marker, options]);
		
		return null;
	}

	// the google.maps.Map() call must be in a separate component
	function Map({ center, zoom, children }) {
		const ref = useRef();
		const [ map, setMap ] = useState();
		
		useEffect(() => {
			if (!map) {
				var m = new window.google.maps.Map(ref.current, { center, zoom });
				m.addListener('click', () => {
					// close the open infoWindow when clicking on the map
					// or on any marker. this also closes the previous info window
					// when clicking from marker to marker.
					if (infoWindow) {
						infoWindow.close();
					}
				});
				setMap(m);
			}
		}, [map, center, zoom]);
		
		return ( 
			<div style={{ height: '400px' }}>
				<div ref={ref} id="map" style={{ height: '100%' }}></div>
				{React.Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						// set the map prop on the child component
						return React.cloneElement(child, { map });
					}
				})}
			</div>
		);
	}
	
	const center = { lat: 42.397, lng: -90.644 };
	const zoom = 4;
	
	// transform latlong a bit for google.
	// filter ones that are missing lat or long. they will still be shown in the direcotry table.
	const listings = entries.map((e) => {
		e.position = {
			lat: e.lat,
			lng: e.long
		};
		return e;
	}).filter((e) => {
		return e.lat && e.long;
	});
	
	function RenderPosition({ listing }) {
		var icons = [];
		var size = 30;
		var bsIconStyle = { width: size+'px', height: size+'px' };
		var taIconStyle = { width: (size*1.6)+'px' };
		// borrowed fontawesome 4 tripadvisor svg (has been removed from latest and doesnt exist in bootstrap-icons)
		if (listing.tripadvisor_url) {
			icons.push(<a key="ta" title="View on TripAdvisor" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.tripadvisor_url}><TripAdvisor style={taIconStyle} /></a>);
		}
		if (listing.google_url) {
			icons.push(<a key="g" title="View on Google" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.google_url}><Google style={bsIconStyle} /></a>);
		}
		if (listing.facebook_url) {
			icons.push(<a key="f" title="View on Facebook" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.facebook_url}><Facebook style={bsIconStyle} /></a>);
		}
		if (listing.website_url) {
			icons.push(<a key="f" title="View website" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.website_url}><Globe style={bsIconStyle} /></a>);
		}
		
		return (
			<div className="text-start">
				<h3>{listing.name}</h3>
				<p>
					{listing.address}<br />
					{listing.city}, {listing.province}<br />
					{listing.country_code}
					{!!listing.phone_number && <><br />{listing.phone_number}</>}
				</p>
				{icons}
			</div>
		);
	}
	
	return (
		<Wrapper apiKey={apiKey} render={render}>
			<Map center={center} zoom={zoom}>
				{listings.map((listing) => (
					<Marker key={listing.name} position={listing.position} content={<RenderPosition listing={listing} />} />
				))}
			</Map>
		</Wrapper>
	);
}