import { GetUserFromLocalStorage } from './User';

import history from './history';

const axios = require('axios').default;

const httpClient = axios.create();
httpClient.defaults.timeout = 5000;
httpClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

// add auth header with user token if its set
httpClient.interceptors.request.use(function (config) {
	let user = GetUserFromLocalStorage();
	if (user) {
		config.headers.Authorization = `Bearer ${user.token}`;
	} 
	else {
		delete config.headers.Authorization;
	}
	return config;
});

// if an authenticated request returns a bad_token error, we should silently end the session (log out).
// this can happen if the user has multiple sessions open on different devices
// and logs out, or if we impersonate a user and log out when they had a session open.
httpClient.interceptors.response.use((response) => {
	if (false === response?.data?.success && 'bad_token' === response?.data?.error) {
		history.replace('/logout'); // push() seems to hit logout twice, use replace()
		// fake a successful response so we dont pop an error alert message.
		return { data: { success: true } };
	}
	return response;
});

export const getDirectory = () => {
	return httpClient.get(`/v1/directory/`)
	.catch(function (error) {
		console.log('getDirectory catch', error);
	});
};

export const trackSocialClick = (session_id, name, href) => {
	return httpClient.post(`/v1/track/click/${session_id}/${name}/${encodeURIComponent(href)}`)
	.catch(function (error) {
		console.log('trackSocialClick catch', error);
	});
};

export const trackLottieView = (session_id, lottie_name) => {
	return httpClient.post(`/v1/lotties/view/${session_id}/${lottie_name}`)
	.catch(function (error) {
		console.log('trackLottieView catch', error);
	});
};

export const getLocationFromServer = (locationId) => {
	return httpClient.get('/v1/organization/location/'+locationId)
	.catch(function (error) {
		console.log('getLocationFromServer catch', error);
	});
};

export const sendLocationToServer = (locationId, location) => {
	return httpClient.put('/v1/organization/location/'+locationId, {
		location: location
	})
	.catch(function (error) {
		console.log('sendLocationToServer catch', error);
	});
};

export const createLocation = (location) => {
	return httpClient.post('/v1/organization/location/', {
		location: location
	})
	.catch(function (error) {
		console.log('createLocation catch', error);
	});
};

export const deleteLocation = (locationId) => {
	return httpClient.delete('/v1/organization/location/'+locationId)
	.catch(function (error) {
		console.log('deleteLocation catch', error);
	});
};

export const createCourse = (locationId, course) => {
	return httpClient.post(`/v1/organization/location/${locationId}/course/`, {
		course: course
	})
	.catch(function (error) {
		console.log('createCourse catch', error);
	});
};

export const getCourseFromServer = (locationId, courseId) => {
	return httpClient.get(`/v1/organization/location/${locationId}/course/${courseId}`)
	.catch(function (error) {
		console.log('getCourseFromServer catch', error);
	});
};

export const deleteCourse = (locationId, courseId) => {
	return httpClient.delete(`/v1/organization/location/${locationId}/course/${courseId}`)
	.catch(function (error) {
		console.log('getCourseFromServer catch', error);
	});
};

export const sendCourseToServer = (locationId, courseId, course) => {
	return httpClient.put(`/v1/organization/location/${locationId}/course/${courseId}`, {
		course: course
	})
	.catch(function (error) {
		console.log('sendCourseToServer catch', error);
	});
};

export const ServerVerifyToken = (token) => {
	return httpClient.post('/v1/verify', {
		token: token
	})
	/*.then(function (response) {
		if (!response?.data?.success) {
			console.log('logging in: ', response);
		}
	})*/
	.catch(function (error) {
		console.log('token post catch', error);
	});
};

export const ServerLogout = () => {
	return httpClient.post('/v1/logout')
	/*.then(function (response) {
		if (!response?.data?.success) {
			console.log('logging in: ', response);
		}
	})*/
	.catch(function (error) {
		console.log('login post catch', error);
	});
};

export const ServerLogin = (email) => {
	return httpClient.post('/v1/login', {
		email: email
	})
	/*.then(function (response) {
		if (!response?.data?.success) {
			console.log('logging in: ', response);
		}
	})*/
	.catch(function (error) {
		console.log('login post catch', error);
	});
};

export const sendStateToServer = (state, colours) => {
	httpClient.post('/v1/state', {
		state: state,
		colours: colours
	})
	.then(function (response) {
		if (!response?.data?.success) {
			console.log('error posting state: ', response);
		}
	})
	.catch(function (error) {
		console.log('post catch', error);
	});
};

export const validateOrg = (org) => {
	return httpClient.post('/v1/organization/validate', {
		organization: org
	})
	.catch(function (error) {
		console.log('get catch', error);
		// we need course data to load a game, not much we can do
	});
};

export const sendOrgToServer = (org) => {
	return httpClient.post('/v1/organization/', {
		organization: org
	})
	.catch(function (error) {
		console.log('post organization catch', error);
	});
};

export const getCourseStateFromServer = (courseId, sessionId) => {
	return httpClient.get(`/v1/course/${courseId}/state/${sessionId}`)
	.catch(function (error) {
		console.log('get catch', error);
		// we need course data to load a game, not much we can do
	});
};

export const getOrgFromServer = () => {
	return httpClient.get(`/v1/organization/`)
	.catch(function (error) {
		console.log('organization get catch', error);
	});
};