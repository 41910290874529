import { useEffect } from 'react';

// helper to allow usage of scripts hosted externally (eg stripe).
// try not to use.

const useScript = url => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = url;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
};

export default useScript;