import { useEffect, useRef } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';

export default function LottiePlayer(props) {
	const player = useRef(null);

	useEffect(() => {
		// by stopping and starting the lottie, we can trigger it to start when 
		// eg. a carousel changes, or whenever it is rerendered. we may or may not want this,
		// but it fixes a bug where when loop is false the animation is already done
		// before we even see it (and since it doesnt loop it doesnt restart).
		if (player.current) {
			player.current.stop();
		}
		setTimeout(() => { 
			if (player.current) {
				player.current.play();
			}
		}, 200);
	});

	var defaultHeight = '250px';	
	var defaultSpeed = '0.7';
	var defaultLoop = true;
	return (
		<Player
			ref={player}
			autoplay={true}
			loop={typeof props.loop !== 'undefined' ? props.loop : defaultLoop}
			keepLastFrame={true}
			speed={props.speed ? props.speed : defaultSpeed}
			src={props.src}
			style={{ height: props.height ? props.height : defaultHeight, width: '100%' }}
		></Player>
	);
}