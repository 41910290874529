import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';

export default function FormErrorList(props) {
	const { t } = useTranslation();
	const errors = props.errors;
	
	if (!errors.length) {
		return;
	}
	return (
		<Alert variant="danger">
			{/* use dangerouslySet so html will be rendered correctly. note this is vulnerable to xss. */}
			{props.errors.map((e, idx) => (
				<div dangerouslySetInnerHTML={{
					__html: t(e)
				}} key={idx}></div>
			))}
		</Alert>
	);
}