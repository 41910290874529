import { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = (props) => {
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
	};
	const handleCloseDoIt = (e) => {
		setShow(false);
		props.onSuccess(e);
	};
	const handleShow = () => {
		setShow(true);
	};

	return (
		<>
			<Button title={props.trigger.title} size={props.trigger.size} variant={props.trigger.variant} onClick={handleShow}>{props.trigger.text}</Button>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{props.body}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>{props.cancelBtnText}</Button>
					<Button variant="success" onClick={handleCloseDoIt}>{props.confirmBtnText}</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ConfirmModal;