import { Row, Col } from 'react-bootstrap';

export default function Terms() {
	return (
		<Row>
			<Col>
				<div className="text-center mb-4">
					<h1>MinigolfMagic.com Terms of Use</h1>
				</div>
				
				<p>These Terms of use which also include the Privacy Policy, Cookies Policy, Data Processing Addendum and Terms of Third-Party Integration ("Terms") set out the terms and conditions for your use of Services offered by Minigolf Magic.</p>
				<p>By clicking "Accept" or using any of the Services, you acknowledge that you have read and understood these Terms, and that you have agreed to these Terms on behalf of any person or entity for whom you are using the Services.</p>
				<p>If you do not agree to any of these Terms, then you should not use the Services. All rights not expressly granted in these Terms are reserved by Minigolf Magic.</p>
				<p>We may change these Terms from time to time by posting updates to the Website. You are advised to check the Terms from time to time for any updates or changes that may impact you and if you do not accept such amendments, you must cease using the Services.</p>
				<p>These Terms of Use were updated on October 1st 2022.</p>
				
				<h4>Definitions</h4>
				<p>To help you read and understand these Terms, we've set out definitions of some key terms below:</p>
				<p>"Customer" means the person or entity who registers to use the Service by creating a Minigolf Magic account or by contacting us to set up a course either for a free trial or a paid subscription. If you are creating an account or using the Services on behalf of a business, you agree that you are accepting these Terms and have the authority to enter into these Terms, on behalf of the business, which will be deemed to be the Customer, and will be bound by these Terms.</p>
				<p>"Player" means any person who uses Minigolf Magic Score Card. They are not Minigolf Magic's Customers, but our Customer's Guests/Customers/Patrons</p>
				<p>"Personal Data" means any Data relating to an identified or identifiable natural person ('data subject'); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person), such as your first and last name, identity number, e-mail address, address and province, telephone number, education details and training records, employment data, financial and / or banking data, as well as other data related to demographics, other (online) contact data and possibly health related data, etc.</p>
				<p>"Sensitive Data" means any data revealing a person's racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetics, biometrics, health data, data about sex life or sexual orientation, or data about criminal convictions and offences.</p>
				<p>"We" or "Minigolf Magic" means 2187320 Ontario Ltd doung business as Minigolf Magic.</p>
				<p>"Service" or "Score Card" means Minigolf Magic's Digital Score Card and any feature composing it.</p>
				<p>"Content" – text, graphics, audio and video materials, documentation, information posted in Apps or Website, perceivable by a person or with the help of special devices.</p>
				<p>"You" means the Customer and (where the context permits) includes any players.</p>

				<h4>Account Information</h4>
				<p>To lawfully use our Services, you must be at least at the age your country of residence defines as the least age to offer information society services.</p>
				<p>You agree to provide true, accurate, current, and complete account information, and to maintain and promptly update your account information to ensure that it remains true, accurate, current, and complete. We will use that information to contact you from time to time, so it is important that you keep all of your contact details up to date</p>

				<h4>Subscription / Cancellation</h4>
				<p>Once the free trial period has expired, you have the choice between continuing or stopping using our Services.</p>
				<p>If you decide to subscribe to our services, your subscription will be prepaid for a period of time as described during the payment process. We offer monthly and annual billing. Your subscription will automatically renew unless canceled - we offer a 30 day money back guarantee on subscription payments if you are unsatisfied with the service for any reason.</p>
				<p>Failure to pay for subscription services will result in the suspension of the services. Your customers will not be able to access your scorecards. </p>
				<p>If you decide not to subscribe to our service, or to stop your subscription, you can do so by accessing your billing portal via your account page or by notifying us via email.When Subscribed, you agree all information about your course can be displayed on your business' space and will be accessible to the public. That includes, but is not limited to hours of opening, location, contact information, description, prices, services, logo, hole by hole par information etc.</p>

				<h4>Subscriptions and fees</h4>
				<p>The fees charged for use of the Services are set out on the Website (or as notified to you in the case of some exclusive plans) ("Fees") and are subject to change. We can change the Fees, and/or introduce new pricing packages and terms, at any time by giving you 14 days' prior notice. The new Fees or pricing/package terms will apply to you from expiry of your previous subscription and you will be alerted of the current pricing 14 to 30 days before the expiration of your subscription. If you don't agree to those pricing changes, you have the right not to renew your subscription.</p>
				<p>Also, the new Fees will apply to you immediately if you choose to change your subscription/package (or if you reactivate your plan after cancellation). Fees are charged in advance on a monthly or annual basis. We are happy to provide refunds in any case in which you are not delighted with the services we provide.</p>
				<p>If you are carrying out a free trial of the Services, the free trial will begin on the day that we set up your course and send you your access information. If you wish to continue to use the Services at the end of the free trial period, you will need to pay by credit card on our website . All Fees are inclusive of all taxes. You indemnify and hold Minigolf Magic harmless against any claims by any tax authority for any underpayment of any sales, use, goods and services, value added or other tax or levy, and any penalties and/or interest. If you are required to withhold or deduct tax from the Fees, you will pay Minigolf Magic such additional amounts as are necessary to ensure receipt of the full amount which would have been received but for the deduction.</p>
				<p>By subscribing to optional add-ons, you agree to pay this additional features by credit card on our website. These add-ons or plan upgrades will be accessible to the players only after we received the payment. If you decide to subscribe additional features outside of the renewal period, we will charge you the extra fees proportionally to the time remaining on your subscription, except for set up fees, which will be due entirely, if applicable. </p>
				<p>Payment of your Fees could incur additional bank fees at the discretion of your bank. Bank fees may be charged for currency conversion and international settlement; and your charge may still be subject to additional fees even if you are being charged by Minigolf Magic in your local currency. If your credit card statement charge differs from your invoice, please contact your bank in the first instance. Minigolf Magic is not liable for any additional bank transaction fees that may be charged.</p>

				<h4>Cancellation of your account</h4>
				<p>
					We can cancel or suspend your account at any time on written notice to you. Such termination will be effective at the end of your then-current paid-up subscription period. We may also cancel or suspend your account immediately if, in our sole discretion:
					<ul>
						<li>you have committed a material or persistent breach of these Terms or any other terms applying to your use of the Services;</li>
						<li>we consider that provision of the Services, or use of them, in your territory or jurisdiction will or is likely to breach any applicable law;</li>
						<li>we decide to withdraw the Services from your territory or jurisdiction altogether; or</li>
						<li>you fail to comply with any limits or restrictions (e.g, on the number of users, stores or transactions) applicable to your plan;</li>
						<li>your use of the account is creating a security or availability risk for Minigolf Magic International Limited or our other customers, is impacting (or may impact) the stability or performance of our systems, or is requiring disproportionate resource to deliver (eg, in terms of storage or processing requirements, support requests or helpdesk queries).</li>
					</ul>
				</p>
				<p>You are entitled to cancel your account with Minigolf Magic at any time. You can request to cancel your account by sending an email to <a target="_blank" rel="noreferrer" href="mailto:minigolfmagic@gmail.com">minigolfmagic@gmail.com</a>. If you cancel your account before the end of your current paid month or year, your cancellation will take effect at the end of the current period.</p>
				<p>If your account is canceled, your account will be deactivated and all of your rights granted under these Terms will immediately come to an end (except to the extent that it is necessary for them to continue in respect of our ongoing storage of your data up to the date of permanent deletion). We are not liable for any loss or damage following, or as a result of, cancellation of your account, and it is your responsibility to ensure that any content or data which you require is exported, backed-up or replicated before cancellation.</p>

				<h4>Cancellation of your account</h4>
				<p>You may upgrade or downgrade your account (for example, add or delete employee accounts and stores, subscribe or unsubscribe from optional add-ons) at any time by sending us an email at <a target="_blank" rel="noreferrer" href="mailto:minigolfmagic@gmail.com">minigolfmagic@gmail.com</a></p>
				<p>The upgrade of your account will become effective within 2 days following the reception of your payment. Only the time remaining on your subscription will be charged, except for set up fees if applicable. The downgrade of your account will become effective immediately. If you downgrade your account, no refunds will be paid in respect of any unused portion of any higher account offering. </p>
				<p>Downgrading your account may cause the loss of content, features, or capacity of your account.</p>
				<p>If you choose to downgrade your account, Minigolf Magic does not accept any liability for the resulting loss of data, content, features or capacity.</p>

				<h4>Disclaimer of Warranties</h4>
				<p>THE USE OF THE SERVICES IS CARRIED OUT AT YOUR OWN RISK. THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE". THE USE OF SERVICES DOES NOT PROVIDE ANY WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING (WITHOUT LIMITATION) IMPLIED NON-INFRINGEMENT WARRANTIES. THE USE OF SERVICES DOES NOT PROVIDE ANY WARRANTIES THAT (I) SERVICES WILL MEET ALL YOUR REQUIREMENTS, (II), SERVICES WILL WORK IN AN UNINTERRUPTED, SECURE, OR ERROR-FREE FASHION, (III) THE RESULTS THAT MAY BE OBTAINED FROM USING SERVICES, WILL BE ACCURATE OR RELIABLE OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED FROM MERCHANTS USING Minigolf Magic WILL MEET ALL YOUR REQUIREMENTS.</p>
				
				<h4>Disclaimer</h4>
				<p>YOU UNDERSTAND AND AGREE THAT BY USING SERVICES, YOU ALONE ARE RESPONSIBLE AND SHALL NOT HOLD Minigolf Magic LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, INCLUDING (WITHOUT LIMITATION) LOSS OF REPUTATION, USE OF PERSONAL DATA OR OTHER INTANGIBLES ON THE BASIS OF THE AGREEMENT, OFFENSES, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE (WITHOUT LIMITATION), RESULTING FROM: (I) THE USE OR INABILITY TO USE SERVICES; (II) SPENDING ON GOODS, THEIR REPLACEMENT AND/OR OTHER AS A RESULT OF DATA, INFORMATION, COMMUNICATIONS RECEIVED FROM Minigolf Magic AND/OR OTHERWISE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR COMMUNICATIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD-PARTY USING SERVICES; OR (V) ANY OTHER MATTER RELATING TO THE USE OF SERVICES.</p>
			
				<h4>No malicious or illegal us</h4>
				<p>
					You must not: 
					<ul style={{listStyle: 'none'}}>
						<li>(a) use the Services in any unlawful manner (or if use of the Services would cause you to breach any law or regulation applicable to you), for any unlawful or fraudulent purpose, or in any manner inconsistent with these Terms; </li>
						<li>(b) infringe our intellectual property rights or those of any third party in relation to your use of the Services; </li>
						<li>(c) transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the Services; </li>
						<li>(d) use the Services in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; </li>
						<li>(e) collect or harvest any information or data from the Services or our systems or attempt to decipher any transmissions to or from the servers running any Services; </li>
						<li>(f) disclose or distribute information relating to another User of the Services to any third party, or use any other User's information for any marketing purposes unless you have that User's express permission to do so;</li>
						<li>(g) access or register User logins via bots or other automated methods.</li>
					</ul>
				</p>
			
				<h4>Reasonable use policy</h4>
				<p>You agree to use the Services in a reasonable way (including, to avoid doubt, if you have subscribed for an 'unlimited' account). If we determine that your use of the Services is not reasonable or that your use is causing degraded performance of the Services for you or for other Users, we may impose limits on your use of the Services. Where possible, we will endeavor to give you at least 24 hours prior notice and request that your usage is reduced before imposing any limits.</p>
				
				<h4>Intellectual Property</h4>
				<p>You acknowledge and agree that Minigolf Magic offers Content that is protected by copyright, patent, trademark, trade secret, or other proprietary rights and laws governing such legal relations.</p>
				<p>Except as expressly provided by the Terms and Conditions, You agree not to use such Content in bad faith, in whole or in part, including but not limited to because such use would be in direct violation of copyright rights and interests, and the rights and interests of other Customers.</p>
				<p>Any use of Services, except as specifically stated in these Terms of Use, is prohibited. Technology and computer software, applications, content, design, images and the like underlying or relating to Services, are protected by copyright and other laws. You agree not to carry out the following actions, namely: copy, modify, create derivative works of, reverse engineer, disassemble or otherwise attempt to derive the source code, sell, assign, sublicense or otherwise transfer rights to Services. Any rights not expressly granted herein are reserved to Minigolf Magic. No part of these Terms of Use shall be construed as granting, by implication, or otherwise, any license or right to use intellectual property rights for Services or parts thereof.</p>
				<p>By posting on the Website or Apps information on trademarks and other data protected by copyright, You represent and warrant the availability of appropriate rights for this placement.</p>
				
				<h4>Governing Law</h4>
				<p>These Terms shall be governed by and construed under the laws of the Country of Canada as applied to agreements entered into and to be performed in Canadian residents. The parties consent to the exclusive jurisdiction and venue of the courts located in and serving the Province of Ontario in Canada.</p>
				
				<h4>General</h4>
				<p>We may work with a number of partners, value added resellers and other third parties to promote, market and integrate other products and services with the Services. In some cases, we may receive a commission from those partners and third parties for referring our customers to them, or we may pay a commission to third parties where customers are referred to us.</p>
				<p>If we don't insist that you perform any of your obligations under these Terms, or if we do not immediately enforce our rights against you, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations.</p>
				<p>The Services are provided for use in business, so you are not a consumer. To the maximum extent permitted by law, any statutory or other consumer protection provisions (including in the Consumer Guarantees Act 1993 and in the Fair Trading Act 1986) do not apply to the Services, these Terms or our relationship with you.</p>
				<p>Each clause in these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.</p>
<p>You are not permitted to assign, sub-licence, novate or transfer these Terms or any of the rights licensed under them.</p>
			</Col>
		</Row>
	);
};