import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { InfoCircleFill, ExclamationTriangleFill, XOctagonFill } from 'react-bootstrap-icons';

import Utils from 'Utils';

import StripePricingTable from 'routes/Organization/StripePricingTable';

function OrganizationAlerts({ org }) {
	var alerts = [];
	
	// count # of locations, courses, holes for this org to show warnings (but still allow adding beyond the limits)
	var orgLocationCount = org.locations.length;
	var orgCourseCount = 0;
	var orgHolesCount = 0;
	var containsDemoCourse = false;
	for (const loc of org.locations) {
		orgCourseCount += loc.courses.length;
		for (const course of loc.courses) {
			orgHolesCount += course.holes;
			// warn: process.env always come back as strings, need toString() here.
			if ([process.env.REACT_APP_DEMO_COURSE_ID_9_HOLES, process.env.REACT_APP_DEMO_COURSE_ID_18_HOLES].includes(course.id.toString())) {
				containsDemoCourse = true;
			}
		}
	}
	
	// suppress all alerts for org that owns demo courses.
	if (containsDemoCourse) {
		alerts.push(
			<Alert key="demo" className="mt-2 mb-0">
				<InfoCircleFill size={24} />{' '}
				Note: usage alerts for this organization are being suppressed as it contains 1 or more demo courses.
			</Alert>
		);
		return alerts;
	}
	
	const isFreePlan = !org.stripe_subscription_id;
	
	var stripeTable = <StripePricingTable linkText="Please add a paid plan" clientId={org.id} numCourses={orgCourseCount} />;
	
	// max_locations=0 means unlimited locations allowed. same for holes/courses.
	if (org.max_locations > 0 && orgLocationCount > org.max_locations) {
		alerts.push(
			<Alert key="locations" variant="warning" className="mt-2 mb-0">
				<ExclamationTriangleFill size={24} />{' '}
				Number of locations for your organization ({orgLocationCount}) exceeds the maximum amount allowed on your {isFreePlan && 'free '}plan ({org.max_locations}).{' '}
				{isFreePlan && <>{stripeTable} to remove this limitation.</>}
			</Alert>
		);
	}
	
	if (org.max_courses && orgCourseCount > org.max_courses) {
		alerts.push(
			<Alert key="courses" variant="warning" className="mt-2 mb-0">
				<ExclamationTriangleFill size={24} />{' '}
				Number of courses for your organization ({orgCourseCount}) exceeds the maximum amount allowed on your {isFreePlan && 'free '}plan ({org.max_courses}).{' '}
				{isFreePlan && <>{stripeTable} to remove this limitation.</>}
			</Alert>
		);
	}
	
	if (org.max_holes && orgHolesCount > org.max_holes) {
		alerts.push(
			<Alert key="holes" variant="warning" className="mt-2 mb-0">
				<ExclamationTriangleFill size={24} />{' '}
				Number of holes for your organization ({orgHolesCount}) exceeds the maximum amount allowed on your {isFreePlan && 'free '}plan ({org.max_holes}).{' '}
				{isFreePlan && <>{stripeTable} to remove this limitation.</>}
			</Alert>
		);
	}

	// if the user doesnt have a subscription, warn about the limited # of free games.
	// after 20 plays (soft limit), show a warning that soon new games wont be allowed.
	// after 50 plays (hard limit), show an error that new games are no longer allowed.
	// note this is configurable in .env
	if (isFreePlan) {
		var playsRemainingSoft = process.env.REACT_APP_SOFT_LIMIT_MAX_FREE_GAMES - org.num_games_completed;
		var playsRemainingHard = process.env.REACT_APP_HARD_LIMIT_MAX_FREE_GAMES - org.num_games_completed;
		if (playsRemainingSoft > 0) {
			alerts.push(
				<Alert key="soft" className="mt-2 mb-0">
					<InfoCircleFill size={24} />{' '}
					You have {playsRemainingSoft} free play{Utils.S(playsRemainingSoft)} remaining at your course.{' '}
					{stripeTable} to remove this limitation.
				</Alert>
			);
		}
		else if (playsRemainingHard > 0) {
			alerts.push(
				<Alert key="stop" variant="warning" className="mt-2 mb-0">
					<ExclamationTriangleFill size={24} />{' '}
					You have exceeded the number of free plays at your course. After {playsRemainingHard} more play{Utils.S(playsRemainingHard)}, starting new games will not be allowed.{' '}
					{stripeTable} to continue using Minigolf Magic.
				</Alert>
			);
		}
		else {
			alerts.push(
				<Alert key="stop" variant="danger" className="mt-2 mb-0">
					<XOctagonFill size={24} />{' '}
					You have exceeded the number of free plays at your course. Starting new games is no longer allowed.{' '}
					{stripeTable} to continue using Minigolf Magic.
				</Alert>
			);
		}
	}
	
	return alerts;
}

OrganizationAlerts.propTypes = {
	org: PropTypes.object.isRequired
};

export default OrganizationAlerts;