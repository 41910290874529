import { Row, Col, Form } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { Spinner, Button, Table, FloatingLabel } from 'react-bootstrap';

import { useParams, useNavigate } from 'react-router-dom';

import { createCourse } from 'API';

const CourseForm = (props) => {
	const course = props.course;
	
	const [ count, setCount ] = useState();
	const [ submitted, setSubmitted ] = useState(false);
	const buttonRef = useRef();

	useEffect(() => {
	       if (buttonRef.current) { 
		       // wires button disabled state to spinner
		       buttonRef.current.disabled = submitted;
	       }
	});
	
	const ParEntry = (props) => {
		var rows = [];
		for (var i = 0; i < count; i++) {
			var defaultValue = (props.pars && i in props.pars) ? props.pars[i] : null;
			rows.push(
				<tr key={i}>
					<td className="text-center align-middle">{i+1}</td>
					<td><Form.Control type="number" max="10" min="1" required defaultValue={defaultValue} id={`par${i}`} inputMode="numeric" /></td>
				</tr>	
			);
		}
		
		return (
			<Table striped bordered>
				<thead>
					<tr>
						<th>Hole #</th>
						<th>Par</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</Table>	
		);
	};
	
	return (
		<Form onSubmit={(e) => { setSubmitted(true); props.onSubmit(e); }}>
			<Row>
				<Col>
					<h2>{props.heading}</h2>
					<p>Details about the minigolf course. You will have an opportunity to add more courses later.</p>
					<FloatingLabel label="Course Name"  className="mb-3" controlId="name">
						<Form.Control type="text" name="locationName" maxLength="100" required defaultValue={course?.name} />
						<Form.Text muted>The course name is something like "9 Hole Course" or "Pirate Adventure Golf" - it can be changed later.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="maxGroupSize" label="Maximum Group Size" className="mb-3">
						{/* all of this is necessary, type="number" and inputmode="numeric" for min/max to work */}
						<Form.Control type="number" max="10" min="1" required inputMode="numeric" defaultValue={course?.max_group_size} />
						<Form.Text muted>Enter the maximum number of players allowed in a group.</Form.Text>
					</FloatingLabel>
					<Row>
						<Col>
							<FloatingLabel controlId="maxStrokes" label="Max # of Strokes">
								<Form.Control type="number" max="10" min="1" required inputMode="numeric" defaultValue={course?.max_strokes} />
							</FloatingLabel>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<Form.Check defaultChecked={course?.max_strokes_type==='fixed'} required type="radio" name="maxStrokesType" value="fixed" id="maxStrokesFixed" label="Fixed" />
								<Form.Check defaultChecked={course?.max_strokes_type==='par_plus'} required type="radio" name="maxStrokesType" value="par_plus" id="maxStrokesParPlus" label="Par Plus" />
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-3">
						<Form.Text muted style={{marginTop: '-0.5rem'}}>Enter the maximum number of strokes allowed per hole.</Form.Text>
					</Row>
					<FloatingLabel controlId="numHoles" label="Number of Holes" className="mb-3">
						<Form.Control type="number" max="50" min="1" required inputMode="numeric" defaultValue={course?.holes} onChange={(e) => { setCount(e.target.value); }} />
						<Form.Text muted>After entering the number of holes, enter the par for each hole below.</Form.Text>
					</FloatingLabel>
					<ParEntry pars={course?.par} />
					{/* <Form.Group controlId="courseMap" className="mb-3">
						<Form.Label>(Optional) Upload course map:</Form.Label>
						<Form.Control type="file" />
					</Form.Group> */}
					<div className="d-grid gap-2">
						<Button ref={buttonRef} variant="success" type="submit">
							{!submitted && 'Save Changes'}
							{submitted && <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Saving...</>}
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
};

export default function CourseEdit() {
	const { locId } = useParams();
	const navigate = useNavigate();
	 
	const onSubmit = (e) => {
		e.preventDefault();
		
		var numHoles = e.target['numHoles'].value;
		var par = [];
		var course_map = 'test_file'; // file upload
		for (var i = 0; i < numHoles; i++) {
			var p = e.target['par'+i].value;
			par.push(p);
		}

		var course = {
			name: e.target['name'].value,
			max_group_size: e.target['maxGroupSize'].value,
			holes: numHoles,
			par: par,
			course_map: course_map,
			max_strokes: e.target['maxStrokes'].value,
			max_strokes_type: e.target['maxStrokesType'].value
		};

		createCourse(locId, course).then(response => {
			if (!response?.data?.success) {
				console.log(locId, course);
				console.log('error creating course: ', response);
				return;
			}
			navigate('/organization/');
		});	
	};
	
	return <CourseForm onSubmit={onSubmit} heading="Add Course" />;
}