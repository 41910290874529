import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { ServerLogin, ServerVerifyToken } from 'API';
import { useEffect, useContext, useState, useRef } from 'react';
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { UserContext } from 'UserContext';
import { LogIn } from 'User';
import Loading from 'Loading';

import { useTranslation } from 'react-i18next';

import FormErrorList from 'FormErrorList';

export default function Login() {
	const { token } = useParams();
	const [sent, setSent] = useState(false);
	const [errors, setErrors] = useState([]);
	const [invalid, setInvalid] = useState(false);
	const nav = useNavigate();
	const { user, setUser } = useContext(UserContext);
	const { t } = useTranslation();
	const [submitted, setSubmitted] = useState(false);
	const buttonRef = useRef();

	useEffect(() => {
	       if (buttonRef.current) { 
		       // wires button disabled state to spinner
		       buttonRef.current.disabled = submitted;
	       }
	});
	
	
	// if user passes a token, like /login/093u039jt3 , verify it 
	// and potentially log them in.
	useEffect(() => {
		setSent(false);
		if (token) {
			ServerVerifyToken(token)
			.then(function(response) {
				if (response?.data?.success) {
					LogIn(response.data, setUser);
					nav('/organization/');
					return;
				}
				setInvalid(true);
			})
		}
	}, [nav, setUser, token]);
	
	if (user) {
		return <Navigate to="/organization/" />;
	}

	if (token) {
		if (invalid) {
			return <span>{t('log_in_verify_error_invalid_token')}</span>;
		}
		return <Loading message={t('log_in_verify_message')} />;
	}
	
	const onSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		setErrors([]);
		let email = e.target.email.value.trim();
		ServerLogin(email).then(function(response) {
			// on success an email will be sent
			// tell them to check their email.
			// on hitting verify, we will do another request and then
			// bounce to their org page
			if (response?.data?.success) {
				setSent(true);
				return;
			}
			var errors;
			if (response.data.error) {
				errors = [response.data.error];
			}
			else {
				errors = ['An unknown error occurred.'];
			}
			setErrors(errors);
			setSubmitted(false);
		});
	};
	
	// success message, 'check your email'
	const LoginFormSubmitted = () => (
		<Row>
			<Col>
				<h1>{t('log_in_email_sent_header')}</h1>
				<p>{t('log_in_email_sent_message')}</p>
			</Col>
		</Row>
	);
	
	// login form
	const LoginForm = () => (
		<Form onSubmit={onSubmit}>
			<Row>
				<Col>
					<h1>{t('log_in_header')}</h1>
					<p>{t('log_in_description')}</p>
					{errors && <FormErrorList errors={errors} />}
					<FloatingLabel controlId="email" maxLength="100" label="Email" className="mb-3">
						<Form.Control type="email" required />
					</FloatingLabel>
					<div className="d-grid gap-2">
						<Button ref={buttonRef} variant="success" type="submit">
							{!submitted && t('log_in_btn')}
							{submitted && <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> {t('log_in_validating')}</>}
						</Button>				
					</div>
				</Col>
			</Row>
		</Form>	
	);
	
	if (sent) {
		return <LoginFormSubmitted />;
	}

	return <LoginForm />;
}