// cant useContext here, need to pass in setUser function from context above
export function LogIn(userObj, setUser) {
	// @todo better
	localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_USER_KEY, JSON.stringify(userObj));
	setUser(userObj);
}

export function ShowTriggerLoginEmailBtn() {
	localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_SHOW_TRIGGER_LOGIN_EMAIL_BTN_KEY, true);
}

export function GetUserFromLocalStorage() {
	let obj = null;
	const user = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER_KEY);
	if (user) {
		obj = JSON.parse(user);
	}
	return obj;
}

export function LogOut(setUser) {
	localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_USER_KEY);
	// also remove the secret flag to show a button that triggers login email (used for rapid signup feature)
	localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_SHOW_TRIGGER_LOGIN_EMAIL_BTN_KEY);
	setUser(null);
}