import { useContext } from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom';
import { UserContext } from './../UserContext';

export default function Wrapper() {
	const { user } = useContext(UserContext);
	
	// as=link will make these work with router. onclick to hide menu once link is clicked. 
	// navkey needed to make navbar collapse on selection in conjunction with collapseOnSelect
	// key so we dont get an error about lists needing a unique key on each element... 
	var guestLinks = [
		<Nav.Link key="1" eventKey="1" as={Link} to={`/scorecard/${process.env.REACT_APP_DEMO_COURSE_ID_9_HOLES}/`}>Start 9 Hole Scorecard</Nav.Link>,
		//<Nav.Link key="2" eventKey="2" as={Link} to={`/scorecard/${process.env.REACT_APP_DEMO_COURSE_ID_18_HOLES}/`}>Start 18 Hole Scorecard</Nav.Link>,
		<Nav.Link key="3" eventKey="3" as={Link} to="/signup">Organization Signup</Nav.Link>,
		<Nav.Link key="4" eventKey="4" as={Link} to="/login">Organization Login</Nav.Link>,
		<Nav.Link key="5" eventKey="5" as={Link} to="/directory/">Course Directory</Nav.Link>
	];
	var userLinks = [
		<Nav.Link key="1" eventKey="1" as={Link} to="/organization/">View My Organization</Nav.Link>,
		<Nav.Link key="2" eventKey="2" as={Link} to="/logout">Logout</Nav.Link>
	];
	
	return (
		<div>
			<Navbar bg="light" expand="md" collapseOnSelect={true}>
				<Container>
					<Navbar.Brand>
						<img alt="Logo" src="/images/icons/icon-72x72.png" width="30" height="30" className="d-inline-block align-top" /> 
						Minigolf Scorecard
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">{ user ? userLinks : guestLinks }</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			{/* add some space from the navbar. minHeight makes footer appear at the bottom for pages < full height */}
			<Container id="container" className="mt-2" style={{minHeight: '89vh'}}>
				<Outlet />
			</Container>
			<footer className="text-center mb-3">
			<Link to="/terms">Terms and Conditions</Link> | <Link to="/privacy">Privacy Policy</Link> | <a target="_blank" rel="noreferrer" href="https://www.facebook.com/minigolfmagic/">Find us on Facebook</a>
			</footer>
		</div>
	);
};