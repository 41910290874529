import { useState, useEffect } from 'react';

import { Row, Col, Table } from 'react-bootstrap';

import DirectoryMap from 'routes/Directory/Map';

import { getDirectory } from 'API';

import { Google, Facebook, Globe } from 'react-bootstrap-icons';

import { ReactComponent as TripAdvisor } from 'tripadvisor.svg';

import Loading from 'Loading';

export default function DirectoryIndex({ listings }) {
	const [loading, setLoading] = useState(true);
	const [entries, setEntries] = useState([]);

	useEffect(() => {
		getDirectory()
		.then((response) => {
			if (!response?.data?.success) {
				alert('error getting directory');
				console.error('error getting directory: ', response);
				return;
			}
			//console.log('get state response', response.data);
			setEntries(response.data.entries);
			setLoading(false);
		});
	}, []); // run once
	
	// loading screen
	if (loading) {
		return <Loading message="Loading directory..." />;
	}

	function GetSocialIcons({ listing, size }) {
		var icons = [];
		// a lot of futzing around to get consistent greyed out styles. also tripadvisor doesnt work with color? svg needs a filter. but bootstrap icons are svg? idk..
		var bsIconStyle = { width: size+'px', height: size+'px' };
		var taIconStyle = { width: (size*1.6)+'px' };
		var greyedOutFilter = 'invert(84%) sepia(0%) saturate(718%) hue-rotate(318deg) brightness(92%) contrast(94%)';
		var taDisabledStyle = { width: (size*2)+'px', filter: greyedOutFilter};
		var bsDisabledStyle = { width: (size*1.4)+'px', height: (size*1.4)+'px', color: '#c0c0c0'};
		// borrowed fontawesome 4 tripadvisor svg (has been removed from latest and doesnt exist in bootstrap-icons)
		if (listing.tripadvisor_url) {
			icons.push(<a key="t" title="View on TripAdvisor" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.tripadvisor_url}><TripAdvisor style={taIconStyle} /></a>);
		}
		else {
			icons.push(<TripAdvisor key="t" className="px-1" style={taDisabledStyle} />);
		}
		if (listing.google_url) {
			icons.push(<a key="g" title="View on Google" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.google_url}><Google style={bsIconStyle} /></a>);
		}
		else {
			icons.push(<Google key="g" className="px-1" style={bsDisabledStyle} />);
		}
		if (listing.facebook_url) {
			icons.push(<a key="f" title="View on Facebook" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.facebook_url}><Facebook style={bsIconStyle} /></a>);
		}
		else {
			icons.push(<Facebook key="f" className="px-1" style={bsDisabledStyle} />);
		}
		if (listing.website_url) {
			icons.push(<a key="w" title="View website" className="px-1 link-dark" target="_blank" rel="noreferrer" href={listing.website_url}><Globe style={bsIconStyle} /></a>);
		}
		else {
			icons.push(<Globe key="w" className="px-1" style={bsDisabledStyle} />);
		}
		return icons;
	}

	return ( 
		<Row>
			<Col>
				<div className="text-center">
					<h1>Minigolf Course Directory</h1>
					<DirectoryMap entries={entries} apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} />
				</div>
				<div className="text-center display-3 mb-3">
					Are we missing your minigolf course?<br />
					<a href="mailto:{process.env.REACT_APP_GET_HELP_EMAIL}">Let us know!</a>
				</div>
				<Table striped hover>
					<thead>
						<tr>
							<th>Course Name</th>
							<th>Phone</th>
							<th>City</th>
							<th>Province/State</th>
							<th>Postal/ZIP</th>
							<th>Country</th>
						</tr>
					</thead>
					<tbody>
						{entries.map((e) => (
							<tr key={e.id}>
								<td>{e.name}</td>
								<td>{e.phone_number}</td>
								<td>{e.city}</td>
								<td>{e.province}</td>
								<td>{e.postal_code}</td>
								<td>{e.country_code}</td>
								<td className="text-nowrap"><GetSocialIcons listing={e} size={20} /></td>
							</tr>	
						))}
					</tbody>
				</Table>
			</Col>
		</Row>
	);
		
		
}