import { useState, useRef, useEffect, useContext } from "react";

import { Row, Col, Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Table from 'react-bootstrap/Table';

import { useNavigate } from "react-router-dom";

import { sendOrgToServer } from 'API';

import { LogIn } from 'User';
import { UserContext } from 'UserContext';

import FormErrorList from 'FormErrorList';

export default function EditCourse(state, setState) {
	const [count, setCount] = useState(0);
	const { setUser } = useContext(UserContext);
	 
	const [submitted, setSubmitted] = useState(false);
	const [errors, setErrors] = useState([]);
	const navigate = useNavigate();

	const buttonRef = useRef();

	useEffect(() => {
	       if (buttonRef.current) { 
		       // wires button disabled state to spinner
		       buttonRef.current.disabled = submitted;
	       }
	});
	
	 
	 
	//  const [inputs, setInputs] = useState([<input />])
	//  useEffect(() => setInputs(
	//    Array.from({length: count}).map(_ => <input />)
	//  ), [count])
	//  return <div><input type="number" value={count}  />{inputs}</div>  
	
	const onSubmit = (e) => {
		e.preventDefault();
		
		var numHoles = e.target['numHoles'].value;
		var par = [];
		var course_map = 'test_file'; // file upload
		for (var i = 0; i < numHoles; i++) {
			var p = e.target['par'+i].value;
			par.push(p);
		}

console.log(e.target['maxStrokesType'].value);

		state.organization.locations[0].courses = [
			{
				name: e.target['name'].value,
				max_group_size: e.target['maxGroupSize'].value,
				holes: numHoles,
				par: par,
				course_map: course_map,
				max_strokes: e.target['maxStrokes'].value,
				max_strokes_type: e.target['maxStrokesType'].value
			}
		];

		// do saving spinner thing here
		
		// trigger the spinner
		setSubmitted(true);
		// send data for validation
		sendOrgToServer(state.organization).then((response) => {
			if (!response?.data?.success) {
				// clear spinner, set error msgs
				setSubmitted(false);
				setErrors([response.data.error]);
				return;
			}
			// log user in
			LogIn(response.data, setUser);
			navigate('/organization/');
			return;
		});
	};
	
	const ParEntry = () => {
		var rows = [];
		for (var i = 0; i < count; i++) {
			rows.push(
				<tr key={i}>
					<td className="text-center align-middle">{i+1}</td>
					<td><Form.Control type="number" max="10" min="1" required  id={`par${i}`} inputMode="numeric" /></td>
				</tr>	
			);
		}
		
		return (
			<Table striped bordered>
				<thead>
					<tr>
						<th>Hole #</th>
						<th>Par</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</Table>	
		);
	};
	
	return (
		<Form onSubmit={onSubmit}>
			<Row>
				<Col>
					<h2>Registration - Course</h2>
					<p>Details about the minigolf course. You will have an opportunity to add more courses later.</p>
					<FormErrorList errors={errors} />
					<FloatingLabel label="Course Name"  className="mb-3" controlId="name">
						<Form.Control type="text" name="locationName" maxLength="100" required />
						<Form.Text muted>The course name is something like "9 Hole Course" or "Pirate Adventure Golf" - it can be changed later.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="maxGroupSize" label="Maximum Group Size" className="mb-3">
						{/* all of this is necessary, type="number" and inputmode="numeric" for min/max to work */}
						<Form.Control type="number" max="10" min="1" required inputMode="numeric" />
						<Form.Text muted>Enter the maximum number of players allowed in a group.</Form.Text>
					</FloatingLabel>
					<Row>
						<Col>
							<FloatingLabel controlId="maxStrokes" label="Max # of Strokes">
								<Form.Control type="number" max="10" min="1" required inputMode="numeric" />
							</FloatingLabel>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<Form.Check required type="radio" name="maxStrokesType" value="fixed" id="maxStrokesFixed" label="Fixed" />
								<Form.Check required type="radio" name="maxStrokesType" value="par_plus" id="maxStrokesParPlus" label="Par Plus" />
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-3">
						<Form.Text muted style={{marginTop: '-0.5rem'}}>Enter the maximum number of strokes allowed per hole.</Form.Text>
					</Row>
					<FloatingLabel controlId="numHoles" label="Number of Holes" className="mb-3">
						<Form.Control type="number" max="50" min="1" required inputMode="numeric" onChange={(e) => { setCount(e.target.value); }} />
						<Form.Text muted>After entering the number of holes, enter the par for each hole below.</Form.Text>
					</FloatingLabel>
					<ParEntry />
					{/* <Form.Group controlId="courseMap" className="mb-3">
						<Form.Label>(Optional) Upload course map:</Form.Label>
						<Form.Control type="file" />
					</Form.Group> */}
					<div className="d-grid gap-2">
						<Button ref={buttonRef} variant="success" type="submit">
							{!submitted && 'Complete Onboarding'}
							{submitted && <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Validating...</>}
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
}