import { Row, Col, Button } from 'react-bootstrap';

import { getCourseFromServer } from 'API';

import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import QRCode from "react-qr-code";

import Loading from 'Loading';

import Utils from 'Utils';

import "css/qr.css";

export default function OrganizationQR() {
	const [course, setCourse] = useState(null);
	const { locId, courseId } = useParams();
	
	useEffect(() => {
		getCourseFromServer(locId, courseId).then(response => {
			if (!response?.data?.success) {
				alert('error getting course');
				console.log('error getting course: ', response);
				return;
			}
			setCourse(response.data.course);
		});
	}, [locId, courseId]); // [] = run once

	if (!course) {
		return <Loading message="Loading QR code..." />;
	}
	
	return (
		<>
			<Row className="text-center mb-5">
				<Col id="course-name">
					<h1>{course.name}</h1>
				</Col>
			</Row>
			<Row className="text-center mb-5 mt-2">
				<Col id="heading">
					<strong>One person keeps score for your whole group (up to {course.max_group_size} player{Utils.S(course.max_group_size)} per group)</strong>
				</Col>
			</Row>
			<Row className="text-center mb-3">
				<Col id="title">
					<strong><em>SCAN FOR YOUR ONLINE SCORECARD</em></strong>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="text-center" id="qrcode">
						<QRCode value={`${process.env.REACT_APP_SITE_URL}/scorecard/${course.id}/`} size={324} />
					</div>
					<div className="float-end mt-4" id="box">
						<Row>
							<Col className="text-nowrap pt-1">
								Works with all modern phones<br />
								No registration or download required<br />
								<strong>Powered by MiniGolfMagic.com</strong>
							</Col>
							<Col>
								<img src="/images/icons/icon-72x72.png" alt="MiniGolfMagic Icon" />
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
			<Row className="text-center mt-5">
				<Col>
					<Button id="print-btn" className="no-print" variant="success" onClick={() => window.print() }>Print</Button>
				</Col>
			</Row>
		</>
	);
}