import { Row, Col } from 'react-bootstrap';

import DemoButtons from 'DemoButtons';

export default function Home() {	
	return (
		<Row>
			<Col className="text-center mt-5">
				<h1 className="cover-heading">The best minigolf scorecard, ever.</h1>
				<p className="lead">That's it.</p>
				<DemoButtons />
			</Col>
		</Row>
	);
}