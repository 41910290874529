import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import { UserContext } from 'UserContext';

// used on home & 404 pages

export default function DemoButtons() {	
	const { user } = useContext(UserContext);
	return (
		<>
			<iframe title="Minigolf Magic Demo" className="mb-3" width="340" height="560" src="https://www.youtube.com/embed/7O0ZFQJFfJg" frameborder="0" allowfullscreen></iframe>
			<p className="lead">
				<Button as={Link} to={`/scorecard/${process.env.REACT_APP_DEMO_COURSE_ID_9_HOLES}/`} variant="success">Try it out - 9 holes</Button>
			</p>
			{user && <Button as={Link} to="/organization/" variant="primary" size="lg">View My Organization</Button>}
		</>
	);
}