import { useState } from 'react';
import { PropTypes } from 'prop-types';

import { Button, Modal } from 'react-bootstrap';

import useScript from 'useScript';

function StripePricingTable({ linkText, clientId, numCourses }) {
	const [ pricingTable, showPricingTable ] = useState(false);
	useScript('https://js.stripe.com/v3/pricing-table.js');
	
	// show a different pricing table if user has >1 course. 
	var tableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
	if (numCourses > 1) {
		tableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_MORE_THAN_ONE_COURSE;
	}
	
	return (
		<>
			{/* react complains about using <a href="#">, use a button and style as a link */}
			<Button variant="link" className="px-0 py-0" style={{verticalAlign: 'baseline'}} onClick={(e) => { e.preventDefault(); showPricingTable(true); }}>{linkText}</Button>
			<Modal show={pricingTable} fullscreen={true} onHide={() => showPricingTable(false)}>
				<Modal.Header closeButton>
					<Modal.Title><h1>Choose A Plan</h1></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* we are allowed to pass customer-email here, but if we do the email cant be changed on the checkout page. 
					 the org email might not be the email the customer wants to use for checkout, so omitting this field. */}
					<stripe-pricing-table 
						client-reference-id={clientId}
						pricing-table-id={tableId}
						publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
					>
					</stripe-pricing-table>	
				</Modal.Body>
			</Modal>
		</>
	);
};

StripePricingTable.propTypes = {
	linkText: PropTypes.string.isRequired,
	clientId: PropTypes.number.isRequired,
	numCourses: PropTypes.number.isRequired
};

export default StripePricingTable;