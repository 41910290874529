import { useState, useRef, useEffect } from "react";

import { Row, Col, Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { validateOrg } from 'API';

import FormErrorList from 'FormErrorList';

export default function EditOrganization(state, setState) {
	const [submitted, setSubmitted] = useState(false);
	const [errors, setErrors] = useState([]);
	
	const buttonRef = useRef();
	
	useEffect(() => {
		if (buttonRef.current) { 
			// wires button disabled state to spinner
			buttonRef.current.disabled = submitted;
		}
	});
	
	const onSubmit = (e) => {
		e.preventDefault();
		var org = {
			name: e.target['organizationName'].value,
			contact_name: e.target['contactName'].value,
			contact_email: e.target['contactEmail'].value
		};
		// trigger the spinner
		setSubmitted(true);
		// send data for validation
		validateOrg(org).then((response) => {
			if (!response?.data?.success) {
				// clear spinner, set error msgs
				setSubmitted(false);
				setErrors([response.data.error]);
				return;
			}
			state.organization = org;
			state.step = 2;
			setState({...state});
		});
	};
	
	return (
		<Row>
			<Col>
				<Form onSubmit={onSubmit}>
					<h2>Register Your Organization</h2>
					<p>Tell us about your business and we'll get your digital scorecard ready in no time at all!</p>
					<p>An organization owns 1 or more minigolf courses. What is the name of your organization?</p>
					<FormErrorList errors={errors} />
					<FloatingLabel controlId="organizationName" maxLength="100" label="Organization Name" className="mb-3">
						<Form.Control type="text" required />
					</FloatingLabel>
					<FloatingLabel controlId="contactName" maxLength="100" label="Contact Name" className="mb-3">
						<Form.Control type="text" required />
					</FloatingLabel>
					<FloatingLabel controlId="contactEmail" maxLength="100" label="Contact Email" className="mb-3">
						<Form.Control type="email" required />
					</FloatingLabel>
					<div className="d-grid gap-2">
						<Button ref={buttonRef} variant="success" type="submit">
							{!submitted && 'Continue'}
							{submitted && <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Validating...</>}
						</Button>
					</div>
				</Form>
			</Col>
		</Row>
	);
};