import { Row, Col, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function EditLocation(state, setState) {
	const onSubmit = (e) => {
		e.preventDefault();
		var location = {};
		e.target.querySelectorAll('input,select').forEach(({id, value}) => {
			location[id] = value;
		});
		state.organization.locations = [location];

		state.step = 3;
		setState({...state});
	};

	return (
		<Form onSubmit={onSubmit}>
			<Row>
				<Col>
					<h2>Registration - Location</h2>
					<p>Details about the location. A location has 1 or more courses. You will have an opportunity to add more locations later.</p>
					<FloatingLabel label="Location Name" className="mb-3" controlId="name">
						<Form.Control type="text" maxLength="100" required />
						<Form.Text muted>The location name is the name of your fun center like "Family Funzone" or "Great Wolf Lodge Niagara Falls" - it can be changed later.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="address" label="Address" className="mb-3">
						<Form.Control type="text" maxLength="100" required />
					</FloatingLabel>
					<FloatingLabel controlId="city" label="City" className="mb-3">
						<Form.Control type="text" maxLength="100" required />
					</FloatingLabel>
					<FloatingLabel controlId="state" label="State/Province" className="mb-3">
						<Form.Control type="text" maxLength="100" required />
					</FloatingLabel>
					<FloatingLabel controlId="country" label="Country" className="mb-3">
						<Form.Select required>
							<option></option>
							<option value="CA">Canada</option>
							<option value="DE">Germany</option>
							<option value="US">United States</option>
						</Form.Select>
					</FloatingLabel>
					<FloatingLabel controlId="zip" label="ZIP/Postal" className="mb-3">
						<Form.Control type="text" maxLength="10" required />
					</FloatingLabel>
					<FloatingLabel controlId="phone" label="Phone Number" className="mb-3">
						<Form.Control type="tel" maxLength="20" required />
					</FloatingLabel>
					<FloatingLabel controlId="report_problem_email" label="Report Problem Email" className="mb-3">
						<Form.Control type="email" maxLength="200" />
						<Form.Text muted>The email address where guests can report problems.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="concessions_url" label="Concessions URL" className="mb-3">
						<Form.Control type="url" maxLength="200" />
						<Form.Text muted>The URL where your users can buy concessions (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="facebook_url" label="Facebook URL" className="mb-3">
						<Form.Control type="url" maxLength="200" />
						<Form.Text muted>The URL for your location's Facebook page (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="tripadvisor_url" label="TripAdvisor URL" className="mb-3">
						<Form.Control type="url" maxLength="200" />
						<Form.Text muted>The URL for your location's TripAdvisor page (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="google_url" label="Google URL" className="mb-3">
						<Form.Control type="url" maxLength="200" />
						<Form.Text muted>The URL for your location's Google Reviews page (optional).</Form.Text>
					</FloatingLabel>
					<div className="d-grid gap-2">
						<Button variant="success" type="submit">Continue</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
}