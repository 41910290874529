import { useState, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Spinner, FloatingLabel } from 'react-bootstrap';

import { useNavigate } from "react-router-dom";

import { sendOrgToServer } from 'API';

import { LogIn, ShowTriggerLoginEmailBtn } from 'User';
import { UserContext } from 'UserContext';

export default function RapidSignup() {
	const [ searchParams ] = useSearchParams();
	const buttonRef = useRef();
	const [submitted, setSubmitted] = useState(false);
	const navigate = useNavigate();
	const { setUser } = useContext(UserContext);
	
	const onSubmit = (e) => {
		e.preventDefault();
		var org = {};
		var loc = {};
		e.target.querySelectorAll('input,select').forEach(({id, value}) => {
			var parts = id.split('.');
			if ('org' === parts[0]) {
				org[parts[1]] = value;
			}
			else {
				// org
				loc[parts[1]] = value;
			}
		});

		loc.courses = [ { skip_course: true } ];

		org.locations = [ loc ];

		// trigger the spinner
		setSubmitted(true);
		// send data for validation
		sendOrgToServer(org).then((response) => {
			if (!response?.data?.success) {
				// clear spinner, set error msgs
				setSubmitted(false);
				alert('error: '+response.data.error);
				return;
			}
			// log user in
			LogIn(response.data, setUser);
			ShowTriggerLoginEmailBtn();
			//LogIn(response.data.token, setUser);
			navigate('/organization/');
			return;
		});
	};
	
	// given these optional fields in the query string, display an org and location edit page so eric
	// can key the email, hit save and be logged in, add a course manually, then a button to send a login email
	
	/*
	org.name
	org.contact_name
	
	location.name
	location.city
	location.state
	location.country
	location.zip
	location.phone
	
	
	location.report_problem_email
	location.concessions_url
	location.facebook_url
	location.google_url
	location.tripadvisor_url
	*/
	
	// to debug: console.log([...searchParams]);
	
	return (
		<Row>
			<Col>
				<Form onSubmit={onSubmit}>
					<h2>Organization</h2>
					<FloatingLabel controlId="org.name" maxLength="100" label="Organization Name" className="mb-3">
						<Form.Control type="text" required defaultValue={searchParams.get('org.name')} />
					</FloatingLabel>
					<FloatingLabel controlId="org.contact_name" maxLength="100" label="Contact Name" className="mb-3">
						<Form.Control type="text" required defaultValue={searchParams.get('org.contact_name')} />
					</FloatingLabel>
					<FloatingLabel controlId="org.contact_email" maxLength="100" label="Contact Email" className="mb-3">
						<Form.Control type="email" required defaultValue={searchParams.get('org.contact_email')} />
					</FloatingLabel>
					
					<h2>Location</h2>
					<FloatingLabel label="Location Name" className="mb-3" controlId="location.name">
						<Form.Control type="text" maxLength="100" required defaultValue={searchParams.get('location.name')} />
						<Form.Text muted>The location name is the name of your fun center like "Family Funzone" or "Great Wolf Lodge Niagara Falls" - it can be changed later.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="location.address" label="Address" className="mb-3">
						<Form.Control type="text" maxLength="100" required defaultValue={searchParams.get('location.address')} />
					</FloatingLabel>
					<FloatingLabel controlId="location.city" label="City" className="mb-3">
						<Form.Control type="text" maxLength="100" required defaultValue={searchParams.get('location.city')} />
					</FloatingLabel>
					<FloatingLabel controlId="location.state" label="State/Province" className="mb-3">
						<Form.Control type="text" maxLength="100" required defaultValue={searchParams.get('location.state')} />
					</FloatingLabel>
					<FloatingLabel controlId="location.country" label="Country" className="mb-3">
						<Form.Select required defaultValue={searchParams.get('location.country')}>
							<option></option>
							<option value="CA">Canada</option>
							<option value="DE">Germany</option>
							<option value="US">United States</option>
						</Form.Select>
					</FloatingLabel>
					<FloatingLabel controlId="location.zip" label="ZIP/Postal" className="mb-3">
						<Form.Control type="text" maxLength="10" required defaultValue={searchParams.get('location.zip')} />
					</FloatingLabel>
					<FloatingLabel controlId="location.phone" label="Phone Number" className="mb-3">
						<Form.Control type="tel" maxLength="20" required defaultValue={searchParams.get('location.phone')} />
					</FloatingLabel>
					<FloatingLabel controlId="location.report_problem_email" label="Report Problem Email" className="mb-3">
						<Form.Control type="email" maxLength="200" defaultValue={searchParams.get('location.report_problem_email')} />
						<Form.Text muted>The email address where guests can report problems.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="location.concessions_url" label="Concessions URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={searchParams.get('location.concessions_url')} />
						<Form.Text muted>The URL where your users can buy concessions (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="location.facebook_url" label="Facebook URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={searchParams.get('location.facebook_url')} />
						<Form.Text muted>The URL for your location's Facebook page (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="location.tripadvisor_url" label="TripAdvisor URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={searchParams.get('location.tripadvisor_url')} />
						<Form.Text muted>The URL for your location's TripAdvisor page (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="location.google_url" label="Google URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={searchParams.get('location.google_url')} />
						<Form.Text muted>The URL for your location's Google Reviews page (optional).</Form.Text>
					</FloatingLabel>
					<div className="d-grid gap-2">
						<Button ref={buttonRef} variant="success" type="submit">
							{!submitted && 'Create Org & Location'}
							{submitted && <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Saving...</>}
						</Button>
					</div>
				</Form>
			</Col>
		</Row>
	)
}