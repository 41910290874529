import { Routes, Route, Navigate } from 'react-router-dom';
// was using browserrouter, need to use historyrouter in order to replace() location from within axios api
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

// need to define history in a separate file so we can load it in API as well as here
import history from './history';

import Wrapper from './routes/Wrapper';
import Terms from './routes/Terms';
import Privacy from './routes/Privacy';
import OrganizationWrapper from './routes/Organization/OrganizationWrapper';
import OrganizationCreate from './routes/Organization/OrganizationCreate';
import OrganizationById from './routes/Organization/OrganizationById';
import OrganizationQR from './routes/Organization/OrganizationQR';
import LocationAdd from './routes/Organization/LocationAdd';
import LocationEdit from './routes/Organization/LocationEdit';
import CourseEdit from './routes/Organization/CourseEdit';
import CourseAdd from './routes/Organization/CourseAdd';
//import { ScorecardStart } from './routes/Scorecard/ScorecardStart';
import { ScorecardGenerateSession } from './routes/Scorecard/ScorecardGenerateSession';
import NotFound from './routes/NotFound';
import Home from './routes/Home';
import Login from './routes/Login';
import Logout from './routes/Logout';
import RapidSignup from 'routes/RapidSignup';
import DirectoryIndex from 'routes/Directory/Index';

import Loading from 'Loading';

import { UserContext } from './UserContext';

import { GetUserFromLocalStorage } from './User';

import { useState, Suspense, lazy } from 'react';

import './i18n';

import './css/App.css';

// bootstrap sitewide
import 'bootstrap/dist/css/bootstrap.min.css';

// attempt to code-split the scorecard stuff out, should reduce size by 1/2
const ScorecardStart = lazy(() => import('./routes/Scorecard/ScorecardStart'));

const App = () => {
	const [user, setUser] = useState(GetUserFromLocalStorage());
	
	return (
		<UserContext.Provider value={{ user, setUser }}>
			<HistoryRouter history={history}>
				{/* suspense used for lazy() which is only for scorecard. */}
				<Suspense fallback={<Loading message="Loading game..." />}>
					<Routes>
						<Route path="/" element={<Wrapper />}>
							<Route index element={<Home />} />
							<Route path="login" element={<Login />}>
								<Route path=":token" />
							</Route>
							<Route path="logout" element={<Logout />} />
							<Route path="terms" element={<Terms />} />
							<Route path="privacy" element={<Privacy />} />
							<Route path="signup" element={ < OrganizationCreate / > } />
							<Route path="organization" element={<OrganizationWrapper />}>
								<Route index element={ < OrganizationById / > } />
								<Route path="qr/:locId/:courseId/" element={ <OrganizationQR /> } />;
								{/* nesting routes leads to a problem where we have something like /edit/ without a param and we want to 
								    have it go to 404, but i dont see an easy way. the element will get rendered when the child is rendered
								    so then we have to check if outlet is null.. this ends up being more straightforward.
								    note we want these to fall under organizationwrapper so we can add a button in the footer for all org 
								    pages where the user is logged in, but not rapid-signup or regular signup. */}
								<Route path="location/add/" element={ <LocationAdd /> }/>
								<Route path="location/:locId/" element={ <LocationEdit /> }/>
								<Route path="location/:locId/course/add/" element={ <CourseAdd /> }/>
								<Route path="location/:locId/course/:courseId/" element={ <CourseEdit /> }/>
							</Route>
							<Route path="*" element={ < NotFound / > } /> 
							<Route path="rapid-signup" element={<RapidSignup />} />
							<Route path="directory" element={<DirectoryIndex />} />
						</Route>
						{/* move scorecard routes outside of / since we dont want to use <Wrapper> */}
						<Route path="scorecard/:courseId/:sessionId/" element={ <ScorecardStart /> }/>
						<Route path="scorecard/:courseId/" element={ <ScorecardGenerateSession /> }/>
						<Route path="scorecard/" element={ <Navigate to="/" /> }/>
					</Routes>
				</Suspense>
			</HistoryRouter>
		</UserContext.Provider>
	);
};

export default App;