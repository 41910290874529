import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";

import { Row, Col, Button } from 'react-bootstrap';
import { EnvelopeFill } from 'react-bootstrap-icons';

import { UserContext } from 'UserContext';

export default function OrganizationWrapper() {
	const { user } = useContext(UserContext);

	// check for user logged in
	if (!user) {
		return <Navigate to="/" />;	
	}
	
	var subject = 'I Need Help!';
	var body = `Hi,

I'm logged in to my MiniGolf Magic account (${user.contact_email}) and I need help with something:

<Please describe the issue you're experiencing here>


Thanks!
${user.contact_name}





For internal use (please leave this intact):
${process.env.REACT_APP_ADMIN_URL}/#${user.org_id}`;
	
	// apparently android mail will show +'s if we leave them in, while gmail on pc/iphone works fine.
	// eric says replace + with ' ', seems against spec but will try it.
	var getHelpQS = new URLSearchParams({ subject: subject, body: body }).toString().replace(/\+/g, ' ');

	return (
		<>
			<Outlet />
			<Row className="text-center mb-5 mt-5" id="get-help-btn">
				<Col>
					<Button variant="primary" size="lg" target="_blank" href={`mailto:${process.env.REACT_APP_GET_HELP_EMAIL}?${getHelpQS}`}><EnvelopeFill size={28} /> Get Help</Button>
				</Col>
			</Row>
		</>
	);
}