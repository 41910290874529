import { Row, Col, Form } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { Spinner, FloatingLabel, Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import { createLocation } from 'API';

const LocationForm = (props) => {
	const loc = props.location;
	
	const [submitted, setSubmitted] = useState(false);
	const buttonRef = useRef();

	useEffect(() => {
	       if (buttonRef.current) { 
		       // wires button disabled state to spinner
		       buttonRef.current.disabled = submitted;
	       }
	});
	
	return (
		<Form onSubmit={(e) => { setSubmitted(true); props.onSubmit(e); }}>
			<Row>
				<Col>
					<h2>{props.heading}</h2>
					<p>Details about the location. A location has 1 or more courses. You will have an opportunity to add more locations later.</p>
					<FloatingLabel label="Location Name" className="mb-3" controlId="name">
						<Form.Control type="text" maxLength="100" required defaultValue={loc?.name} />
						<Form.Text muted>The location name is the name of your fun center like "Family Funzone" or "Great Wolf Lodge Niagara Falls" - it can be changed later.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="address" label="Address" className="mb-3">
						<Form.Control type="text" maxLength="100" required defaultValue={loc?.address} />
					</FloatingLabel>
					<FloatingLabel controlId="city" label="City" className="mb-3">
						<Form.Control type="text" maxLength="100" required defaultValue={loc?.city} />
					</FloatingLabel>
					<FloatingLabel controlId="state" label="State/Province" className="mb-3">
						<Form.Control type="text" maxLength="100" required defaultValue={loc?.state} />
					</FloatingLabel>
					<FloatingLabel controlId="country" label="Country" className="mb-3">
						<Form.Select required defaultValue={loc?.country}>
							<option></option>
							<option value="CA">Canada</option>
							<option value="DE">Germany</option>
							<option value="US">United States</option>
						</Form.Select>
					</FloatingLabel>
					<FloatingLabel controlId="zip" label="ZIP/Postal" className="mb-3">
						<Form.Control type="text" maxLength="10" required defaultValue={loc?.zip} />
					</FloatingLabel>
					<FloatingLabel controlId="phone" label="Phone Number" className="mb-3">
						<Form.Control type="tel" maxLength="20" required defaultValue={loc?.phone} />
					</FloatingLabel>
					<FloatingLabel controlId="report_problem_email" label="Report Problem Email" className="mb-3">
						<Form.Control type="email" maxLength="200" defaultValue={loc?.report_problem_email} />
						<Form.Text muted>The email address where guests can report problems.</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="concessions_url" label="Concessions URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={loc?.concessions_url} />
						<Form.Text muted>The URL where your users can buy concessions (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="facebook_url" label="Facebook URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={loc?.facebook_url} />
						<Form.Text muted>The URL for your location's Facebook page (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="tripadvisor_url" label="TripAdvisor URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={loc?.tripadvisor_url} />
						<Form.Text muted>The URL for your location's TripAdvisor page (optional).</Form.Text>
					</FloatingLabel>
					<FloatingLabel controlId="google_url" label="Google URL" className="mb-3">
						<Form.Control type="url" maxLength="200" defaultValue={loc?.google_url} />
						<Form.Text muted>The URL for your location's Google Reviews page (optional).</Form.Text>
					</FloatingLabel>
					<div className="d-grid gap-2">
						<Button ref={buttonRef} variant="success" type="submit">
							{!submitted && 'Add Location'}
							{submitted && <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> Saving...</>}
						</Button>
					</div>
				</Col>
			</Row>
		</Form>	
	);
};

export default function LocationAdd() {
	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		console.log('onsubmit');
		var location = {};
		e.target.querySelectorAll('input,select').forEach(({id, value}) => {
			location[id] = value;
		});
		createLocation(location).then(response => {
			if (!response?.data?.success) {
				alert('error creating location');
				console.log('error creating location: ', response);
				return;
			}
			navigate('/organization/');
		});
	};
	
	return <LocationForm onSubmit={onSubmit} heading="Add A Location" />;
	
	// load location data
	
	// fill in form
	
	// save comes back here
	
	// on save, go back to organization page
}