import LottiePlayer from 'LottiePlayer';
import animationData from 'lotties/loading.json';

export default function Loading(props) {
	// @todo a timeout if something goes wrong?
	return (
		<div className="text-center">
			<LottiePlayer height={400} src={animationData} />
			<h3>{props.message}</h3>
		</div>
	);
}