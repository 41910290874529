import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Button, Card } from 'react-bootstrap';
import { CreditCard, Building, Geo, At, Flag, Trash3Fill, PencilSquare } from 'react-bootstrap-icons';

import { getOrgFromServer, deleteLocation, deleteCourse, ServerLogin } from 'API';

import Loading from 'Loading';

import OrganizationAlerts from 'routes/Organization/OrganizationAlerts';

import ConfirmModal from 'ConfirmModal';

import Utils from 'Utils';

// the header row of the location and course cards, with the icon, title, and buttons.
const CardHeader = (props) => (
	<Card.Header as="h5" style={{paddingLeft: '5px', paddingRight: '5px'}}>
		{/* dont use bootstrap Table since we dont want builtin styling here */}
		<table style={{width:'100%'}}>
			<tbody>
				<tr>
					<td style={{paddingRight: '5px'}}>{props.icon}</td>
					{/* width 100 on the name cell to force it to be as big as possible, and thus the others as small as possible */}
					<td style={{width:'100%'}}>{props.name}</td>
					<td className="text-nowrap">
						<Button as={Link} size="sm" variant="primary" to={props.editLink} title="Edit"><PencilSquare size={18} /></Button>{' '}
						<ConfirmModal 
							trigger={{
								text: <Trash3Fill size={18} />, 
								variant: 'danger', 
								size: 'sm', 
								title: 'Delete'
							}} 
							onSuccess={props.onClickDelete} 
							title={props.deleteModalTitle}
							body={props.deleteModalBody}
							confirmBtnText={props.deleteModalConfirmBtnText}
							cancelBtnText={props.deleteModalCancelBtnText}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	</Card.Header>
);

const Location = (props) => {
	const onClickDelete = (locationId) => {
		deleteLocation(locationId).then((response) => {
			if (!response?.data?.success) {
				console.error('failed to delete location');
				return;
			}
			// trigger a relookup of org and a page render
			props.setOrgLoaded(false);
		});
	};
	
	let loc = props.location;
	var socialLinks = [];
	if (loc.tripadvisor_url) {
		socialLinks.push(<a key="ta" target="_blank" rel="noreferrer" href={loc.tripadvisor_url}>TripAdvisor</a>);
	}
	if (loc.google_url) {
		socialLinks.push(<a key="g" target="_blank" rel="noreferrer" href={loc.google_url}>Google</a>);
	}
	if (loc.facebook_url) {
		socialLinks.push(<a key="fb" target="_blank" rel="noreferrer" href={loc.facebook_url}>Facebook</a>);
	}
	
	return (
		<Card>
			<CardHeader 
				icon={<Geo />} 
				name={loc.name} 
				editLink={`/organization/location/${loc.id}/`} 
				onClickDelete={() => { onClickDelete(loc.id); }}
				deleteModalTitle="Confirm Deleting Location"
				deleteModalBody="Are you sure you want to delete this location? This will also delete any courses at this location."
				deleteModalConfirmBtnText="Delete Location"
				deleteModalCancelBtnText="Cancel"
			/>
			<Card.Body>
				<Card.Text>
					{loc.address}<br />
					{loc.city}, {loc.state}<br />
					{loc.country}<br />
					{loc.zip}<br />
					{loc.phone}
					{loc.report_problem_email && <><br />Course problem reports: <a href={`mailto:${loc.report_problem_email}`} target="_blank" rel="noreferrer">{loc.report_problem_email}</a></>}
					{loc.concessions_url && <><br /><a href={loc.concessions_url} target="_blank" rel="noreferrer">Your concessions URL</a></>}
					{socialLinks.length > 0 && <><br />Your social links: {socialLinks.reduce((prev, curr) => [prev, ', ', curr])}</>}
				</Card.Text>
				<Row>
					<Col xs={8}>
						<h4>Courses at this location: {!!!loc.courses.length && 'None'}</h4>
					</Col>
					<Col xs={4} className="text-end">
						<Button as={Link} size="sm" variant="success" to={`location/${loc.id}/course/add`}>Add course</Button>
					</Col>
				</Row>
				{loc.courses.map((course) => (
					<Row className="mt-2" key={course.id}>
						<Col>
							<Course locationId={loc.id} course={course} setOrgLoaded={props.setOrgLoaded} />
						</Col>
					</Row>
				))}
			</Card.Body>
		</Card>
	);
};

const Course = (props) => {
	const onClickDelete = (locationId, courseId) => {
		deleteCourse(locationId, courseId).then((response) => {
			if (!response?.data?.success) {
				console.error('failed to delete course');
				return;
			}
			// trigger a relookup of org and a page render
			props.setOrgLoaded(false);
		});
	};
	
	let course = props.course;
	return (
		<Card>
			<CardHeader 
				icon={<Flag />} 
				name={course.name} 
				editLink={`/organization/location/${props.locationId}/course/${course.id}/`}
				onClickDelete={() => { onClickDelete(props.locationId, course.id); }}
				deleteModalTitle="Confirm Deleting Course"
				deleteModalBody="Are you sure you want to delete this course?"
				deleteModalConfirmBtnText="Delete Course"
				deleteModalCancelBtnText="Cancel"
			/>
			<Card.Body>
				<Card.Text>
					{course.holes} Holes (Par: {course.par.reduce((prev, curr) => [prev, ', ', curr])})<br />
					Maximum {course.max_group_size} player{Utils.S(course.max_group_size)} per group<br />
					Maximum {'par_plus' === course.max_strokes_type && 'Par+'}{course.max_strokes} strokes per hole<br />			
					{course.num_sessions_completed} games have been completed at this course<br />
					<a target="_blank" rel="noreferrer" href={`/scorecard/${course.id}/`}>Start a new game at this course</a><br />
					<a target="_blank" rel="noreferrer" href={`qr/${props.locationId}/${course.id}`}>Get QR Code</a><br />
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

const ViewOrg = (props) => {
	let org = props.org;
	
	const sendLoginEmail = () => {
		ServerLogin(org.contact_email).then(function(response) {
			// on success an email will be sent
			// tell them to check their email.
			// on hitting verify, we will do another request and then
			// bounce to their org page
			if (response?.data?.success) {
				alert('login email sent');
				return;
			}
			var error;
			if (response.data.error) {
				error = response.data.error;
			}
			else {
				error = 'An unknown error occurred.';
			}
			alert('error: '+error);
		});
	};
		
	var showSendLoginEmailBtn = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_SHOW_TRIGGER_LOGIN_EMAIL_BTN_KEY);
	return (
		<div>
			<Row className="mt-4">
				<Col>
					<h2>Welcome, {org.contact_name}</h2>
					<h4><Building /> {org.name}</h4>
					<h4><At /> {org.contact_email} {showSendLoginEmailBtn && <Button size="sm" onClick={() => sendLoginEmail()}>Send Login Email</Button>}</h4> 
					{org.stripe_subscription_id && <h4><CreditCard /> <a target="_blank" rel="noreferrer" href="https://billing.stripe.com/p/login/14k16S4HQ6oe3Re144">Manage your subscription</a></h4>}
					<OrganizationAlerts org={org} />
				</Col>
			</Row>
			<hr />
			<Row className="mb-1">
				<Col>
					<h3>Your locations:</h3>
				</Col>
				<Col className="text-end">
					<Button as={Link} size="sm" variant="success" to="location/add">Add location</Button>
				</Col>
			</Row>
			{org.locations.map((loc) => (
				<Row className="mb-4" key={loc.id}>
					<Col>
						<Location location={loc} setOrgLoaded={props.setOrgLoaded} />
					</Col>
				</Row>
			))}
		</div>
	);
};

export default function OrganizationById() {
	const [org, setOrg] = useState(null);
	// need to use a 2nd state to decide when to (re-)fetch state. 
	// if we just use org and pass [org] as a dependency, setOrg(response.data...) 
	// will triger another server fetch, causing an infinite loop. if we 
	// omit org altogether, then deleting a location/course won't trigger
	// an org lookup from server. this is necessary.
	const [orgLoaded, setOrgLoaded] = useState(false);

	useEffect(() => {
		getOrgFromServer().then(response => {
			if (!response?.data?.success) {
				alert('error getting organization');
				console.log('error getting organization: ', response);
				return;
			}
			setOrg(response.data.organization);
			setOrgLoaded(true);
		});
	}, [orgLoaded]);

	if (!orgLoaded) {
		return <Loading message="Loading organization..." />;
	}
	return <ViewOrg org={org} setOrgLoaded={setOrgLoaded} />;
}