import { Row, Col } from 'react-bootstrap';

import DemoButtons from 'DemoButtons';

export default function NotFound() {
	return (
		<Row>
			<Col className="text-center">
				<h1>Oops, that page can't be found</h1>
				{/* if we like this image we should buy it: http://www.illustrationsof.com/5680-royalty-free-golf-clipart-illustration */}
				<img className="mt-3" style={{height: 200}} alt="Not Found" src="/images/404.png"/>
				<p className="mt-4">Maybe start a new scorecard:</p>
				<DemoButtons />
			</Col>
		</Row>
	);
};